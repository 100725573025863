import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Testimonial from "../../components/testimonial";
import { NewsCarousel } from "../../components/newsCarousel.en";
import ContactPerson from "../../components/contactPerson";
import { randomGenerator } from "../../utils";
import { ServicesTeaser } from "../../components/servicesTeaser";
import { PartnersGrid } from "../../components/partnersGrid";

import tk from "../../assets/images/logos/svg/tk.svg";
import generali from "../../assets/images/logos/svg/generali.svg";
import lebensbaum from "../../assets/images/logos/svg/lebensbaum.svg";
import swp from "../../assets/images/logos/svg/swp.svg";
import haspa from "../../assets/images/logos/svg/haspa.svg";
import hansemerkur from "../../assets/images/logos/svg/hansemerkur.svg";
import eppendorf_green from "../../assets/images/logos/svg/eppendorf_green.svg";
import cosmos_green from "../../assets/images/logos/svg/cosmos_green.svg";
import dak_gesundheit_green from "../../assets/images/logos/svg/dak-gesundheit_green.svg";

const Index = () => {
  const images = useStaticQuery(graphql`
    {
      heroes: allFile(
        sort: { fields: relativePath }
        filter: { relativePath: { glob: "home/hero-home-*" } }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH)
          }
        }
      }
      dirk: file(relativePath: { eq: "profiles/dirk-schroedter.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const Testimonials = [
    {
      image: dak_gesundheit_green,
      name: "Erik Ober",
      company: "DAK-Gesundheit",
      position: "Product Owner DAK.de",
      text: "An attractive, informative and easy-to-use website is more than just a business card. It is increasingly becoming a decision criterion for a health insurance company. With the current development of a strong, customer-oriented website, we are taking the next big step towards becoming a digital pioneer.",
      linkTo: "dak-gesundheit",
    },
    {
      image: cosmos_green,
      name: "Andreas Scheffler",
      company: "CosmosDirekt",
      position: "CosmosDirekt Rebranding Project Lead",
      text: "A fantastic result and a great project: thanks to the long hours we put in over the last few months, our customer now has a new website with all the latest features. And working with Monday Consulting has been a pleasure throughout the project!",
      linkTo: "cosmosdirekt",
    },
    {
      image: eppendorf_green,
      name: "Christiane Klebig",
      company: "Eppendorf Group",
      position: "Senior Manager Digital Communications at Eppendorf Group",
      text: "With the support provided by Monday Consulting, we were able to implement a dynamic content management system based on FirstSpirit over a very short time frame, which gave our employees in 33 countries user-friendly access to the collective knowledge of the Eppendorf Group.",
      linkTo: "eppendorf",
    },
  ];

  return (
    <Layout lang="en">
      <Seo
        title="Monday Consulting – digital projects to match your requirements"
        description="We offer first-class expertise for frontend and backend development, CMS implementation, conception and design with a focus on accessible and personalized experiences."
        keywords="Monday Consulting, CMS, CoreMedia, Crownpeak, FirstSpirit, Frontend, Backend, Consulting, Support, Conception, Development, Integration, Design, Infrastructure, Personalization, Accessibility, Netlify, Dynamic Yield, IAAP, Formcentric, Form Management"
        language="en-us"
      />
      <div className="d-md-block parallax-translate bg-shape-vertigo"></div>
      <div className="hero text-color-lg-white mt-4 mt-lg-0">
        <header className="hero-heading mb-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-size-2 text-size-md-1">
                  Digital projects for all requirements.
                  <br />
                  Today and tomorrow.
                </h1>
                <p className="text-size-4 text-size-md-3">
                  We are specialized in the accessible implementation of
                  personalized customer experiences – for your long-term
                  success.
                </p>
                <Link
                  to="/en/services"
                  className="btn bg-monday-skyblue text-color-white text-color-hover-monday-skyblue text-color-hover-lg-white"
                >
                  Our services
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div className="content-container">
          <div className="bg-gradient bg-monday-green">
            <GatsbyImage
              image={
                images.heroes.nodes[
                  randomGenerator(0, images.heroes.nodes.length - 1)
                ].childImageSharp.gatsbyImageData
              }
              className="hero-image"
              alt="Digital projects for all requirements. Today and in the future"
            />
          </div>
        </div>
      </div>

      <NewsCarousel />

      <PartnersGrid
        title="Specialization for your success"
        teaser="With our strong partnerships, we create accessible and individual experiences of tomorrow."
      />

      <ServicesTeaser
        title="Discover our possibilities"
        text={
          "With our expertise in strategy and conception, we work together to create high-quality solutions that meet tomorrow's standards today.\nWe provide comprehensive services, encompassing the selection of the most suitable content management system, unique design and creation, and optimization of front-end, back-end, and infrastructure, all from a single source.\n\nWe guide you through the entire process and provide first-class customer care even after the project has been completed."
        }
        ctaLink="/en/services/serviceportfolio"
        ctaText="Our portfolio of services"
      />

      <div
        className="content-container vertical-spacing bg-monday-green bg-pattern-vertigo text-color-white text-center parallax-background"
        data-spacing-bottom="testimonials"
      >
        <div className="container pt-4 pt-md-5 pb-3">
          <div className="row mb-3">
            <div className="col-12 d-flex flex-column align-items-center">
              <h2 className="mb-3">
                These companies have moved to Monday Consulting
              </h2>
              <p>
                Companies in a wide range of industries use our tailor-made and
                future-proof solutions to secure success in the market by
                optimizing internal processes and improving their customer
                dialog. No two cases are the same — a fact of which we are
                particularly proud.
              </p>
              <Link className="btn" to="/en/references">
                Our case studies
              </Link>
            </div>
          </div>
          <div className="row mt-4 mb-2 align-items-center justify-content-center">
            <div className="p-2 flex-grow-0 mb-2">
              <img src={generali} alt="Generali" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={lebensbaum} alt="Lebensbaum" height="75px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={tk} alt="Techniker Krankenkasse" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={hansemerkur} alt="hamburg.de" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={swp} alt="Stadtwerke Potsdam" height="50px" />
            </div>
            <div className="p-2 flex-grow-0 mb-2">
              <img src={haspa} alt="Hamburger Sparkasse" height="50px" />
            </div>
          </div>
        </div>
      </div>

      <div
        id="testimonials"
        className="container vertical-offset mb-5 mb-md-6 px-0 px-md-3"
        data-offset="50%"
      >
        <div className="swiper-container reveal">
          <div className="swiper-wrapper">
            {Testimonials.map((item, i) => (
              <Testimonial key={i} {...item} />
            ))}
          </div>
          <div className="swiper-pagination d-xl-none"></div>
        </div>
      </div>

      <ContactPerson
        headline="Interested in learning more about our services?"
        text="Call or email us today to find out how we can help you sharpen your digital competitive edge."
        contactEmail="business@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Dirk Schrödter, Chief Executive"
        withGetInTouch={false}
      >
        <GatsbyImage
          image={images.dirk.childImageSharp.gatsbyImageData}
          alt="Dirk Schrödter"
        />
      </ContactPerson>
    </Layout>
  );
};

export default Index;
